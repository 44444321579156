body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5e5f64;
}

div,
span {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #001941;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}
div::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

/* ### SWEET ALERT STYLES ### */
.swal2-container {
  z-index: 9999999 !important;
}

.swal2-container .swal2-popup {
  border: 4px solid #001941 !important;
  border-radius: 4px !important;
}

.swal2-icon.swal2-warning {
  border-color: #8f0018 !important;
  color: #8f0018 !important;
}

.customSweetConfirmButton {
  background-color: #001941 !important;
  border-color: #001941 !important;
  box-shadow: 0 1px 0 rgba(#001941, 0.15) !important;

  border: none !important;
  color: #fff !important;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15) !important;
  line-height: 1.5 !important;
  padding: 8px 12px !important;
  font-size: 16px !important;
  border-radius: 4px !important;
  min-width: 160px !important;
  cursor: pointer !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.customSweetConfirmButton:hover {
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(#001941, 0.3) !important;
}

.customSweetCancelButton {
  background-color: #54667a !important;
  border-color: #54667a !important;
  box-shadow: 0 1px 0 rgba(#54667a, 0.15) !important;

  border: none !important;
  color: #fff !important;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15) !important;
  line-height: 1.5 !important;
  padding: 8px 12px !important;
  font-size: 16px !important;
  border-radius: 4px !important;
  min-width: 160px !important;
  cursor: pointer !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.customSweetCancelButton:hover {
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(#54667a, 0.3) !important;
}

.customSweetSelect {
  display: inline-block !important;
  padding: 10px 20px !important;
  margin: 10px !important;
  border: 2px solid #e9ecef !important;
  border-radius: 4px !important;
  color: #5e5f64 !important;
  font-size: 15px !important;
  -webkit-apperance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+')
    no-repeat 95% 50% !important;
}

.customSweetContainer {
  width: 100% !important;
  max-width: 800px !important;
  
}

